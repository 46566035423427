
export const serverAppConfig = {
  "storeId": "66714da3c95ba1fac09e1bc9",
  "analytics": null,
  "privacy": null,
  "storeType": "B2C",
  "storeDeliveries": [
    {
      "id": "66e2f47888b52d0125a840f2",
      "name": "Delivery 1",
      "label": "5-8  dager",
      "buyable": true
    }
  ],
  "language": "no",
  "currency": null,
  "labels": {
    "assistantChatWith": "Chat med",
    "assistantChatWithFallback": "Kunde",
    "assistantShrink": "Skjul",
    "assistantExpand": "Utvid",
    "assistantMinimize": "Minimer",
    "assistantReply": "Svar",
    "assistantStatusSent": "Sendt",
    "assistantErrorServiceUnavailable": "Tjenesten er utilgjengelig",
    "assistantErrorUndelivered": "Ikke levert",
    "assistantConversationStatusRecording": "Lytter...",
    "assistantConversationStatusProcessing": "Behandler...",
    "assistantConversationStatusProcessingResponse": "Forbereder svar...",
    "assistantConversationStatusProcessingResponding": "Svarer...",
    "assistantConversationStatusError": "Noe gikk galt",
    "changePwdFormMsg": "Passordet må inneholde minst ett tall, én liten og én stor bokstav, ett spesialtegn og være mellom 8 og 20 tegn langt.",
    "changePwdFormOldPwd": "Gammel passord",
    "changePwdFormNewPwd": "Nytt passord",
    "changePwdFormRepeatPwd": "Gjenta passord",
    "changePwdFormBtnLabel": "Lagre",
    "loginForgottenPwd": "Glemt passord?",
    "loginForgottenPwdDesc": "En konto blir automatisk opprettet for deg når du legger inn din første bestilling, og instruksjoner sendes med bekreftelsen på bestillingen.",
    "toLogin": "Tilbake til login",
    "requestPwd": "Be om nytt passord",
    "accountRegistration": "For å kunne følge dette og andre bestillinger lettere, kan du opprette et passord for kontoen.",
    "contactUsFormName": "Navn",
    "contactUsFormPhone": "Telefon",
    "contactUsFormEmail": "E-post",
    "contactUsFormOrderDate": "Bestillingsdato",
    "contactUsFormProduct": "Produkt",
    "contactUsFormOrderNumber": "Ordrenummer",
    "contactUsFormDescription": "Beskrivelse",
    "contactUsFormLocation": "Plassering",
    "contactUsFormBtnLabel": "Send",
    "contactUsFormAttachFiles": "Legg ved filer",
    "contactConfirmationMessage": "Takk for at du sendte inn forespørselen din! Vi vil kontakte deg så snart som mulig.",
    "discountFormName": "Ønsker du å legge til en rabattkode?",
    "discountFormBtnLabel": "Legg til rabatt",
    "loginPageTitle": "Logg Inn",
    "loginFormEmail": "E-post",
    "loginFormPwd": "Passord",
    "loginFormBtnLabel": "Logg inn",
    "magicLinkFormEmail": "E-post",
    "magicLinkFormBtnLabel": "E-post",
    "profileFormFirstName": "Fornavn",
    "profileFormMiddleName": "Mellomnavn",
    "profileFormLastName": "Etternavn",
    "profileFormOrganizationName": "Organisasjonsnavn",
    "profileFormGender": "Kjønn",
    "profileFormDateOfBirth": "Fødselsdato",
    "profileFormBtnLabel": "Send",
    "logout": "Logg ut",
    "accountUserGreeting": "Velkommen",
    "accountHandlerAccountTitle": "Konto",
    "accountHandlerOrdersTitle": "Bestillinger",
    "accountHandlerProfileTitle": "Profil",
    "accountHandlerChangePasswordTitle": "Endre passord",
    "customerOrderOrderNumber": "Ordrenummer",
    "customerOrderPaymentDate": "Betalingsdato",
    "customerOrderStatus": "Status",
    "customerOrderShippingCost": "Fraktkostnad",
    "customerOrderDiscount": "Rabatt",
    "customerOrderAmount": "Beløp",
    "customerOrderDetailInformation": "Informasjon",
    "customerOrderDetailDeliveryAddress": "Leveringsadresse",
    "customerOrderDetailAddress": "Adresse",
    "customerOrderDetailPostalCode": "Postnummer",
    "customerOrderDetailAttention": "Merk",
    "customerOrderDetailMobile": "Mobil",
    "customerOrderDetailYourOrder": "Din bestilling",
    "customerOrderDetailPcs": "stk.",
    "noOrdersMessage": "Ingen bestillinger funnet",
    "noOrdersBtnLabel": "Fortsett å handle",
    "emptyCart": "Ingen elementer funnet",
    "cartServiceShowDetails": "Vis detaljer",
    "cartServiceHideDetails": "Skjul detaljer",
    "allCategoriesTitle": "Alle kategorier",
    "subcategoriesShowMoreBtnLabel": "Mer",
    "subcategoriesNoCategories": "Ingen kategorier funnet",
    "subcategoriesToolbarTitle": "Kategorier",
    "allCategoriesListDynamic": "Kategori",
    "checkoutNewsLetter": "Føl deg fri til å abonnere på nyhetsbrevet vårt for å motta oppdateringer om produktene våre og spesielle tilbud: ",
    "newsLetterInputLabel": "Din e-post",
    "emptyCartBtnLabel": "Start å handle",
    "continueShopping": "Fortsett å handle",
    "checkoutCartCartItemsMsg": "produkter i kurven",
    "checkoutPageTitle": "Kasse",
    "checkoutServicesHeading": "Tjenester",
    "addToCart": "Legg til i handlevogn",
    "noServices": "Ingen tjenester",
    "cartTotal": "Totalt",
    "cartToCheckout": "Til handlekurv",
    "checkoutSummaryDiscount": "Rabatt",
    "checkoutSummarySummaryItemProducts": "Produkter",
    "checkoutSummarySummaryItemServices": "Tjenester",
    "checkoutSummaryCompletePayment": "Fullfør betaling",
    "checkoutSummaryNoProducts": "Ingen produkter funnet i kurven",
    "checkoutSummaryDiscountMsg": "Viktig: Hvis prisen på varen allerede er nedsatt, kan den bare ha effekt på originalprisen på varen.",
    "checkoutSummarySummaryTotal": "Sum",
    "checkoutSummarySummaryTotalDiscount": "Total rabatt",
    "checkoutSummarySummaryContinueToPayment": "Fortsett til betaling",
    "confirmationPageRedirectBtnLabel": "Mine sider",
    "confirmationPageUnknownError": "Ukjent feil",
    "orderIdNotFound": "Ordre-ID ikke funnet",
    "klarnaBackToCart": "Tilbake til handlekurv",
    "productFilesFileLabel": "dokument",
    "updateCart": "Oppdater",
    "productDetailDeliveryLabelUnavailable": "ikke tilgjengelig",
    "previousPageBtnLabel": "gå tilbake",
    "productRequest": "Produktforespørsel",
    "productListItemBuyToDetails": "Gå til detalj",
    "productNotActive": "Dette produktet er ikke aktivt",
    "articleNumber": "Artikkelnummer",
    "gtin": "GTIN",
    "brand": "Merker",
    "brandsTitle": "Merker",
    "stockLevel": "Lagerbeholdning",
    "unit": "enheter",
    "productTabsDescriptionTitle": "Beskrivelse",
    "productTabsSpecificationTitle": "Spesifikasjon",
    "productTabsAttachedFilesTitle": "Vedlagte filer",
    "productTabsNoDescription": "Ingen beskrivelse",
    "productTabsReviewsTitle": "Anmeldelser",
    "notInStock": "Ikke tilgjengelig på lager",
    "noMedias": "Ingen bilder",
    "withMedias": "Se bilder",
    "accessories": "Tilbehør",
    "variants": "Varianter",
    "variantNotFound": "Variant ikke funnet",
    "showMoreBtnLabel": "Mer",
    "priceTitle": "Pris",
    "moreFiltersTitle": "Mer",
    "reset": "Tilbakestill filtre",
    "cleanFilters": "Rens",
    "search": "Søk",
    "sort": "Sorter",
    "showMoreFilters": "Vis flere filtre",
    "filterTitle": "Filter",
    "filterCategoriesTitle": "Kategori",
    "filterBrandsTitle": "Merke",
    "filterPriceRangeTitle": "Pris",
    "filterClear": "Tilbakestill filtre",
    "filterSubmitText": "Vis produkter",
    "filterRangeSelectorFrom": "Fra",
    "filterRangeSelectorTo": "til",
    "productListVerticalTitle": "Sorter og filtrer",
    "productListSearchTitle": "Sorter og filtrer",
    "viewTotalProducts": "se produkter",
    "noProductsFound": "Ingen produkter funnet",
    "removeFilter": "Fjern filter",
    "uniqueOption": "Unik",
    "notValuesFound": "Ingen verdier funnet",
    "sortAndFilter": "Sorter og filtrer",
    "dropzoneDropMsg": "Slipp filene dine her eller klikk for å laste opp",
    "dropzoneAcceptedFiles": "Aksepterte filer",
    "dropzoneReadyMsg": "Klar til å laste opp",
    "dropzoneRejectedMsg": "Avviste filer",
    "prisFilterTitle": "Pris",
    "searchPlaceholder": "Søk produkter",
    "followUs": "Følg oss",
    "searchPanelCloseSearch": "Lukk søk",
    "searchPanelOpenSearch": "Åpne søk",
    "searchPopularResults": "Populære resultater:",
    "registerAccount": "Registrer",
    "signupFormEmail": "Epost",
    "signupFormFirstName": "Fornavn",
    "signupFormLastName": "Etternavn",
    "signupFormBtnLabel": "Registrer",
    "signUpSuccessMsg": "Kontoen har blitt registrert, du vil snart motta en e-post med instruksjoner.",
    "signUpErrorMsgRecoverPassword": "Har du mistet passordet ditt? Gjenopprette det ",
    "signupRecoverPasswordLink": "her",
    "signupInvalidCredentials": "Ugyldige legitimasjonsbeskrivelser",
    "marketplaceProductsIsAvailable": "Tilgjengelig",
    "marketplaceProductsIsNotAvailable": "Ikke tilgjengelig",
    "marketplaceProductsShowMore": "Vis mer",
    "marketplaceProductsShowLess": "Vis mindre",
    "marketplaceProductsNoVendorsFound": "Ingen leverandører funnet",
    "showAll": "Vis alle",
    "requestSent": "Forespørsel sendt"
  },
  "paths": {
    "brandsUrlPath": "/varumarken",
    "categoriesUrlPath": "/categories",
    "tagsUrlPath": "/t",
    "productsUrlPath": "/p",
    "blogsUrlPath": "/blog"
  },
  "staticLinks": [
    {
      "id": "blog",
      "label": "Blogg",
      "path": "/blog"
    }
  ],
  "logoUrl": "https://storage.googleapis.com/gecko-media/EC_1033/store/Vigneron%20-%20Experience%20-%20Black.png",
  "showCart": true,
  "sortingOptions": [
    {
      "sortBy": "price",
      "sortOrder": "DESC",
      "sortLabel": "Pris høy-lav",
      "default": false
    },
    {
      "sortBy": "price",
      "sortOrder": "ASC",
      "sortLabel": "Pris lav-høy",
      "default": true
    },
    {
      "sortBy": "created",
      "sortOrder": "DESC",
      "sortLabel": "Nyeste",
      "default": false
    }
  ],
  "desktopItemHeight": "20vw",
  "mobileItemHeight": "75vw",
  "sliderDefaultValues": [
    0,
    200000
  ],
  "freeShippingOver": 499,
  "shippingProviderConfig": {
    "provider": "INGRID",
    "simpleShipping": {
      "minimumOrderAmountForFreeDelivery": 499,
      "deliveryFee": 139
    },
    "ingridOptions": {
      "purchaseCountry": "NO",
      "purchaseCurrency": "NOK"
    }
  },
  "paymentProviderConfig": {
    "providerName": "NEXI",
    "providerOptions": {
      "nexi": {
        "purchaseCurrency": "NOK",
        "countryCode": "NOR",
        "showMerchantName": null,
        "showOrderSummary": null,
        "completePaymentButtonText": null,
        "termsUrl": "/kopvillkor",
        "cancellationTermsUrl": null
      }
    }
  },
  "filtersBeforeShowMore": 3,
  "sliderMaxPrice": 200000,
  "topNavHeight": {
    "panel": {
      "base": 0,
      "sm": "5vh"
    },
    "content": {
      "base": "8vh",
      "sm": "14vh"
    },
    "spacer": {
      "base": "8vh",
      "sm": "19vh"
    },
    "hero": {
      "base": "92vh",
      "sm": "81vh",
      "md": "60vh"
    }
  },
  "topNavSlideOutFeaturedCategories": 3,
  "socialMediaItems": [
    {
      "label": "Facebook",
      "icon": "FACEBOOK",
      "href": "https://www.facebook.com/Vigneron.se/"
    },
    {
      "label": "Instagram",
      "icon": "INSTAGRAM",
      "href": "https://www.instagram.com/vigneron_experience"
    }
  ],
  "topNavPanel": {
    "items": [
      {
        "icon": "FaCrown",
        "label": "Om oss",
        "href": "/om-vigneron"
      },
      {
        "icon": "GrCatalog",
        "label": "Katalog",
        "href": "https://storage.googleapis.com/gecko-media/EC_1033/landingpage/Vigneron%20Katalog%202024%20Komprimerad.pdf"
      },
      {
        "icon": "CiGlobe",
        "label": "Våre forhandlere",
        "href": "/forhandler"
      }
    ]
  }
};
export const serverTheme = {}; 
