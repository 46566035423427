import * as configNO from './server-app-config-no'
import * as configSV from './server-app-config'
import { AppConfigBase, AppConfigTheme, SiteMetaData } from 'ecosystem'
import manifest from '../public/manifest.json'
import { server } from './custom/api/server'
import { Metadata } from 'next'
import { stdCanonicalFactory } from 'shared-utils'
import { CustomLocale, defaultLocale } from '#/src/i18n'

interface ServerConfig {
  serverAppConfig: AppConfigBase
  serverTheme: AppConfigTheme
}

export class AppConfig {
  private _config: ServerConfig

  constructor(locale: CustomLocale['locale']) {
    switch (locale) {
      case 'no': {
        this._config = configNO as unknown as ServerConfig
        break
      }
      case 'sv':
      default: {
        this._config = configSV as unknown as ServerConfig
        break
      }
    }
  }

  public get siteMetadata(): SiteMetaData {
    return {
      title: manifest.name,
      description: manifest.description,
      image: `${this._config.serverAppConfig.logoUrl}`,
      url: server!,
      keywords: [
        'Vinlagring',
        'Vigneron',
        'Vinkyl',
        'Vinkällare',
        'wine',
        'storage',
        'wine storage'
      ]
    }
  }

  public get config(): AppConfigBase & { site: SiteMetaData; theme: AppConfigTheme } {
    return {
      ...this._config.serverAppConfig,
      site: this.siteMetadata,
      theme: this._config.serverTheme
    }
  }
}

const appConfig = (locale?: CustomLocale['locale']) => {
  return new AppConfig(locale || defaultLocale)
}
export default appConfig

export const defaultAppConfig = appConfig()

type MetadataFactory = Metadata & {
  locale: CustomLocale['locale']
  pathname: string
  title?: string
  description?: string
  images?: string[]
  keywords?: string[]
}
export const metadataFactory = ({
  locale,
  title,
  description,
  images,
  pathname,
  keywords,
  ...rest
}: MetadataFactory): Metadata => {
  // https://github.com/vercel/next.js/issues/35345
  const path = pathname?.replace(/^\/index/, '/')
  const completeUrl = `${defaultAppConfig.siteMetadata.url}${path}`
  const { siteMetadata } = appConfig(locale)

  return {
    title: `${title || siteMetadata.title} - ${siteMetadata.title}`,
    description: description || siteMetadata.description,
    keywords: [...siteMetadata.keywords, ...(keywords ? keywords : [])],
    openGraph: {
      url: completeUrl,
      type: 'website',
      title: `${title || siteMetadata.title} - ${siteMetadata.title}`,
      description: description || siteMetadata.description,
      images: [siteMetadata.image, ...(images ? images : [])]
    },
    twitter: {
      title: `${title || siteMetadata.title} - ${siteMetadata.title}`,
      description: description || siteMetadata.description,
      card: 'summary_large_image',
      images: [siteMetadata.image, ...(images ? images : [])]
    },
    alternates: {
      canonical: stdCanonicalFactory(path, server)
    },
    ...rest
  }
}
