'use client'
import { Metadata } from 'next'
import NotFoundStdPage from 'storefront-modules/common/components/NotFoundStdPage'
import { metadataFactory } from '#/src/appConfig'
import { PageProps } from 'ecosystem'
import { CustomLocale } from '#/src/i18n'
import { getTranslations } from 'next-intl/server'
import { useTranslations } from 'next-intl'

export async function generateMetadata({ params }: PageProps<CustomLocale>): Promise<Metadata> {
  const t = await getTranslations({ locale: params.locale, namespace: 'notFound.Metadata' })

  return {
    ...metadataFactory({
      locale: params.locale,
      title: t('title'),
      description: t('description'),
      pathname: '/'
    })
  }
}

const NotFound = () => {
  const t = useTranslations('notFound.Metadata')
  return <NotFoundStdPage text={t('description')} />
}

export default NotFound
