import { getRequestConfig } from 'next-intl/server'
import { notFound } from 'next/navigation'
import { createSharedPathnamesNavigation, Pathnames } from 'next-intl/navigation'

export const localePrefix = 'always' // todo -- Modify this to 'as-needed' when the english lan is introduced

export const supportedLocales: CustomLocale['locale'][] = ['sv', 'no']
export const defaultLocale = 'sv'

export type CustomLocale = {
  locale: 'sv' | 'no'
}

export const pathnames = {
  '/varumarken': {
    no: '/varemerker',
    sv: '/varumarken'
  },

  '/varumarken/[slug]': {
    no: '/varemerker/[slug]',
    sv: '/varumarken/[slug]'
  }
} satisfies Pathnames<typeof supportedLocales>

export default getRequestConfig(async ({ locale }) => {
  if (!supportedLocales.includes(locale as any)) notFound()

  return {
    messages: (await import(`./custom/intl/${locale}.json`)).default
  }
})

export function i18nLocalizePath(path: string, locale: CustomLocale['locale']): string {
  if (locale === defaultLocale) {
    return path
  }

  return `/${locale}${path}`
}

export const SharedPathnamesNav = createSharedPathnamesNavigation({
  locales: supportedLocales,
  localePrefix
})
